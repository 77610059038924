// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import ahoy from 'ahoy.js'

require.context('images', true)

ahoy.configure({ cookies: false })

document.addEventListener('DOMContentLoaded', () => {
  dataLayer.push({
    event: 'pageView',
    virtualUrl: location.href,
  })

  ahoy.track("pageView", {
    url: document.location.href,
    referer: document.referrer
  })

  document.querySelectorAll("[data-role='vscode']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({ event: 'installVsCode' })
      ahoy.track('installVsCode')
    })
  })

  document.querySelectorAll("[data-role='desktop']").forEach((e) => {
    e.addEventListener('click', (e) => {
      dataLayer.push({ event: 'installDesktop' })
      ahoy.track('installDesktop')
    })
  })

  document.querySelectorAll("[data-role='webapp']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({ event: 'useWebapp' })
      ahoy.track('useWebapp')
    })
  })
})
